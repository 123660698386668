import Router, { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useListBestOffers } from "shared/hooks/bestOffers";
import {
  ISpecialOffer,
  SpecialOfferType,
} from "shared/ts/interfaces/ISpecialOffer";

import Img from "@components/FileServer/Img";
import FormattedMessage from "@components/UI/FormattedMessage";

import { viewPromotionDataLayer } from "@lib/dataLayers";
import { AppState } from "@shared/data/reducers";

import PriceFormatter from "../Formatters/PriceFormatter";

export default function SpecialOffersList() {
  const { specialOfferEnabled, currency, gtmId } = useSelector(
    (state: AppState) => ({
      specialOfferEnabled: state.requestorConfig.specialOfferEnabled,
      currency: state.requestorConfig.currency,
      gtmId:
        state.storage.frontendSettings?.dealerGoogleSettings
          ?.tagManagerContainerId,
    })
  );

  const router = useRouter();

  const intl = useIntl();

  const { specialOffers, airportsCodeBook } = useListBestOffers(
    specialOfferEnabled,
    router.query?.currency as string
  );

  const getSpecialOfferType = (specialOffer): SpecialOfferType | false => {
    const length = specialOffer?.SpecialOfferSteps?.SpecialOfferStep?.length;
    switch (true) {
      case length === 1:
        return "oneway";
      case length === 2:
        return "return";
      case length > 2:
        return "multicity";
      default:
        return false;
    }
  };

  const openSpecialOffer = (
    idSpecialOffer: number,
    dateRange: [{ DateFrom: string; DateTo: string }],
    origin: string,
    destination: string,
    type: SpecialOfferType | false
  ) => {
    const formattedDateRange = {};

    dateRange.forEach((date, i) => {
      formattedDateRange[`dateFrom${i}`] = date.DateFrom;
      const dateTo = new Date(date.DateFrom);
      dateTo.setMonth(dateTo.getMonth() + 3);
      formattedDateRange[`dateTo${i}`] = date.DateTo;
    });
    Router.push({
      pathname: "/bestOffers",
      query: {
        id: idSpecialOffer,
        origin,
        destination,
        type: type || "",
        ...formattedDateRange,
        ...(Router.router.query.lang ? { lang: Router.router.query.lang } : {}),
        ...(Router.router.query.currency
          ? { currency: Router.router.query.currency }
          : {}),
      },
    });
  };

  if (specialOffers.length === 0) {
    return null;
  }

  return (
    <>
      <h2 className="special-offers-headline">
        <FormattedMessage id="SpecialOffersList.headline" />
      </h2>
      <div className="special-offers">
        {specialOffers.map((offer: ISpecialOffer) => (
          <div key={offer.SpecialOfferId} className="special-offers-offer">
            <h3 className="special-offers-offer-h3">
              {
                airportsCodeBook[
                  offer.SpecialOfferSteps.SpecialOfferStep[0].Destination
                ]
              }
            </h3>
            <div className="special-offers-offer-price">
              <PriceFormatter
                price={
                  Router.router.query.currency
                    ? offer.AlternativeCurrencySummaryPrice.FullPrice
                    : offer.SummaryPrice.FullPrice
                }
                customCurrency={Router.router.query.currency || currency}
              />
            </div>
            <button
              onClick={() => {
                if (gtmId) {
                  viewPromotionDataLayer();
                }
                const dateRange = offer.SpecialOfferSteps?.SpecialOfferStep.map(
                  (step) => step.DateRange
                );

                const origin =
                  offer.SpecialOfferSteps?.SpecialOfferStep[0].Origin;
                const destination =
                  offer.SpecialOfferSteps?.SpecialOfferStep[0].Destination;
                const type = getSpecialOfferType(offer);
                return openSpecialOffer(
                  offer.SpecialOfferId,
                  dateRange,
                  origin,
                  destination,
                  type
                );
              }}
              type="button"
              className="button-special-offers-offer button-secondary-color"
            >
              <FormattedMessage id="SpecialOffersList.pickDate" />
            </button>
            <div className="special-offers-offer-from">
              <div className="special-offers-offer-from-destination">
                <Img
                  className="special-offers-offer-ico-plane"
                  src="/static/images/ico-plane.svg"
                  alt="plane icon"
                />
                <span>
                  {
                    airportsCodeBook[
                      offer.SpecialOfferSteps.SpecialOfferStep[0].Origin
                    ]
                  }
                </span>
              </div>
              <div>
                {intl.formatMessage({
                  id: `SpecialOffersList.${getSpecialOfferType(offer)}`,
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
